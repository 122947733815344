<template>
	<div class="dashboard dashboard-group">
		<!-- Header -->
		<div class="flex items-center justify-between mb-8 w-full">
			<div>
				<Toggle
					v-if="isSuperAdmin || isAdmin"
					:checked="includeOtherRoles"
					:onInput="() => includeOtherRoles = !includeOtherRoles"
				/>
				<span
					v-if="isSuperAdmin || isAdmin"
					class="ml-2 cursor-pointer"
					@click="() => includeOtherRoles = !includeOtherRoles"
				>
					Inclure les admins
				</span>
			</div>

			<div>
				<button type="button" class="twn-button mr-4" @click="exportPDF" :disabled="loadingPDF || isLoading">
					<span v-if="!loadingPDF">
						Export PDF
					</span>
					<span v-else>
						Chargement...
					</span>
				</button>

				<button type="button" class="twn-button" @click="ExportReport" :disabled="loadingReport || isLoading">
					<span v-if="!loadingReport">
						Export CSV
					</span>
					<span v-else>
						Chargement...
					</span>
				</button>
			</div>
		</div>

		<!-- Dashboard -->
		<p v-if="isLoading || !trackingData">
			Chargement...
		</p>
		<div v-else>
			<!-- Users, customers and groups -->
			<CategoryBlock title="Connexions">
				<div class="dashboard-kpis">
					<KPIBlock
						title="Durée totale de connexion"
						:time="trackingData.activityData.totalTime"
					/>
					<KPIBlock
						title="Durée moyenne de connexion"
						:time="trackingData.activityData.averageTime"
						help="Durée totale de connexion de l'ensemble des utilisateurs de la promotion / le nombre d'utilisateurs"
					/>
					<KPIBlock
						title="Durée estimée"
						:time="trackingData.activityData.remainingTime"
						:help="(groupType.slug === 'collective_promotion' ?
							'Estimation de la durée nécessaire à la réalisation des séquences à date' :
							'Estimation de la durée nécessaire à la réalisation des séquences à date (fiable a la date de fin de niveau)'
						)"
					/>
				</div>

				<SubCategoryBlock
					title="Durée totale de connexion par apprenant"
					help="La zone grisée correspond à la durée moyenne par utilisateur"
				>
					<p>
						Moyenne de la promotion :
						<span class="font-principal-medium">{{ formatTime(trackingData.activityData.averageTime) }}</span>
					</p>
					<BarChart
						:data="trackingData.activityData.perUser"
						:labels="chartUserLabels"
						:showLegend="false"
						:enableTooltip="true"
						:limit="trackingData.activityData.averageTime"
						:maxBarSize="60"
						:minBarSpace="6"
						format="time"
					/>
				</SubCategoryBlock>

				<SubCategoryBlock title="Avancement des apprenants">
					<!-- Legend -->
					<div class="dashboard-group-progress-legend">
						<p
							v-for="(data, state) in sequenceStateData"
							:key="state"
							:title="data.description"
							:class="{ 'd-none': groupType.slug === 'collective_promotion' && state === 'todo' }"
						>
							<img :src="data.icon">
							<span :class="data.class">{{ data.content }}</span>
							<span>{{ data.name }}</span>
						</p>
					</div>

					<div class="overflow-auto">
						<table>
							<thead>
								<!-- Levels -->
								<tr>
									<th></th>
									<th
										v-for="(level, index) in course.levels"
										:key="level.id"
										:colspan="level.sequences.length"
										class="border-b-1 border-dashboard-gray-dark"
										:class="{
											'border-r-1': (index < course.levels.length - 1)
										}"
									>
										{{ `Niveau ${(level.order + 1)}` }}
									</th>
								</tr>
								<!-- Sequences -->
								<tr>
									<th></th>
									<template v-for="(level, index) in course.levels">
										<th
											v-for="(sequence, sequenceIndex) in level.sequences"
											:key="sequence.id"
											:title="sequence.name"
											class="border-dashboard-gray-dark"
											:class="{
												'border-r-1': (index < course.levels.length - 1 || sequenceIndex < (level.sequences.length - 1))
											}"
										>
											{{ (sequence.name.split(' ')[0] || `${(level.order + 1)}.${(sequence.order + 1)}`) }}
										</th>
									</template>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="user in trackingData.users"
									:key="user.id"
								>
									<td>
										<img class="inline-block" :src="sequenceStateData[user.sequencesState].icon">
										<router-link :to="{ name: 'users-edit', params: { itemID: user.id } }">
											{{ [user.first_name, user.name].join(' ') }}
										</router-link>
									</td>
									<template v-for="level in course.levels">
										<td
											v-for="sequence in level.sequences"
											:key="sequence.id"
											:class="sequenceStateData[user.sequenceState[sequence.id]].class"
											:title="sequenceStateData[user.sequenceState[sequence.id]].description"
										>
											<span>
												{{ sequenceStateData[user.sequenceState[sequence.id]].content }}
											</span>
										</td>
									</template>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="text-center mt-4">
						<button
							type="button"
							class="underline"
							@click="showLegend = !showLegend"
						>
							{{ (showLegend ? 'Cacher' : 'Afficher') }} la légende détaillée
						</button>

						<div v-if="showLegend" class="text-left mt-2">
							<table class="dashboard-group-progress-legend-details">
								<tr
									v-for="(data, state) in sequenceStateData"
									:key="state"
									:class="{ 'd-none': groupType.slug === 'collective_promotion' && state === 'todo' }"
								>
									<td>
										<img :src="data.icon">
										<span :class="data.class">{{ data.content }}</span>
									</td>
									<td>
										<span>
											{{ data.name }}
										</span>
									</td>
									<td>
										<span>{{ data.description }}</span>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</SubCategoryBlock>
			</CategoryBlock>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex"

	import trackingReport from '@/utils/trackingReport'
	import pdfTemplate from '@/utils/pdfTemplate'
	
	import trackingTemplate from "@/constants/promotionTrackingReportTemplate"

	import Toggle from '@/components/Toggle'
	
	import CategoryBlock from "./block/Category"
	import SubCategoryBlock from "./block/SubCategory"
	import KPIBlock from "./block/KPI"
	
	import BarChart from "./chart/Bar"
	
	import GroupPDFTemplate from "./pdfTemplates/Group"

	export default {
		name: 'GroupDashboard',
		props: {
			group: {
				type: Object,
				required: true,
			},
			groupType: {
				type: Object,
				default: null,
			},
			course: {
				type: Object,
				required: true,
			},
		},
		components: {
			Toggle,
			CategoryBlock,
			SubCategoryBlock,
			KPIBlock,
			BarChart,
		},
		data() {
			return {
				isLoading: false,
				trackingData: null,
				loadingReport: false,
				loadingPDF: false,
				includeOtherRoles: false,
				showLegend: false,
				sequenceStateData: {
					done: {
						icon: require('../../assets/images/EMOTE_DONE.svg'),
						class: 'sequence-done',
						name: 'Réalisée',
						content: 'OK',
						description: 'La séquence a été réalisée dans sa totalité.',
					},
					planned: {
						icon: require('../../assets/images/EMOTE_PLANNED.svg'),
						class: 'sequence-planned',
						name: 'Planifiée',
						content: '',
						description: 'La séquence est planifiée mais n\'a pas encore été réalisée.',
					},
					missed: {
						icon: require('../../assets/images/EMOTE_MISSED.svg'),
						class: 'sequence-missed',
						name: 'À réaliser',
						content: '!',
						description: 'La séquence n\'a pas été réalisée à la date programmée.',
					},
					todo: {
						icon: require('../../assets/images/EMOTE_TODO.svg'),
						class: 'sequence-todo',
						name: 'À planifier',
						content: '',
						description: 'La séquence n\'a pas encore été planifiée.',
					},
					late: {
						icon: require('../../assets/images/EMOTE_LATE.svg'),
						class: 'sequence-late',
						name: 'En retard',
						content: 'X',
						description: 'La date limite du parcours est atteinte, l\'utilisateur n\'a pas réalisé sa séquence à temps.',
					},
				},
			}
		},
		computed: {
			...mapGetters('Auth', [ 'isSuperAdmin', 'isAdmin' ]),
			chartUserLabels() {
				return Object.keys(this.trackingData.activityData.perUser).map((userId) => {
					const user = this.trackingData.usersById[userId]

					return [user.first_name, user.name].join(' ')
				})
			}
		},
		watch: {
			includeOtherRoles() {
				this.loadData()
			},
			group: {
				handler() {
					this.loadData()
				},
				immediate: true,
			},
			groupType: {
				handler() {
					this.loadData()
				},
				immediate: true,
			},
			course: {
				handler() {
					this.loadData()
				},
				immediate: true,
			},
		},
		methods: {
			formatTime(value, full = true) {
				const oneHour = (1 * 60 * 60 * 1000)
				let hours = (value / oneHour)
				let rHours = Math.floor(hours)
				let min = Math.round((hours - rHours) * 60)

				return (rHours > 0 ? rHours + 'h ' : '') + (min < 10 ? '0' + min : min) + (full ? 'min' : '')
			},
			async loadData(force = false) {
				if (!this.group?.id || !this.course?.id || !this.groupType?.slug) {
					return
				}

				if (this.isLoading) {
					return
				}

				this.isLoading = true

				// Load tracking data
				const payload = {
					promotionId: this.group.id,
				}

				if (this.includeOtherRoles) {
					payload.roles = ['user', 'organisationadmin', 'administrator', 'superadmin']
					payload.emailFilter = ''
				}

				trackingTemplate.remoteData.promotion = {
					data: this.group
				}
				trackingTemplate.remoteData.promotionType = {
					data: this.groupType
				}
				trackingTemplate.remoteData.course = {
					data: this.course
				}

				this.trackingData = await trackingReport.loadData(this.$store, trackingTemplate, payload, force)

				this.isLoading = false
			},
			async ExportReport() {
				this.loadingReport = true

				try {
					// Set contextual data
					const payload = {
						promotionId: this.group.id,
					}

					if (this.includeOtherRoles) {
						payload.roles = ['user', 'organisationadmin', 'administrator', 'superadmin']
						payload.emailFilter = ''
					}

					trackingTemplate.remoteData.promotion = {
						data: this.group
					}
					trackingTemplate.remoteData.promotionType = {
						data: this.groupType
					}
					trackingTemplate.remoteData.course = {
						data: this.course
					}

					// Create report file
					await trackingReport.export(`TB_${this.group.title}`, this.$store, trackingTemplate, payload)
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error(err)
				}

				this.loadingReport = false
			},
			async exportPDF() {
				this.loadingPDF = true

				pdfTemplate.exportFromComponent(
					`TB_${this.group.title}`,
					GroupPDFTemplate,
					{
						group: this.group,
						course: this.course,
						trackingData: this.trackingData,
					}
				)

				this.loadingPDF = false
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-group {
		.dashboard-chart-bar {
			height: 300px;

			.dashboard-chart-container {
				@apply h-full;

				& > div {
					@apply h-full;
				}
			}

			.ct-grids line {
				stroke: white
			}
			.ct-limit{
				@apply fill-dashboard-gray-light;
			}
		}

		&-progress-legend {
			@apply flex w-full mb-8;

			p {
				@apply mr-6 flex items-center justify-center;

				span:nth-child(2) {
					@apply inline-block mx-2 text-center text-white h-6 w-10;
				}
			}
		}

		table {
			@apply w-full;

			th {
				@apply text-center;
				min-width: 2.5rem;
			}

			td {
				@apply border-1 border-white;

				span {
					@apply block w-full h-full text-center text-white leading-7;
				}
			}

			th, td {
				&:first-child {
					width: 280px;
					min-width: 220px;
				}
			}
		}

		.sequence {
			&-done {
				background: #89E481;
			}
			&-late {
				background: #FF8796;
			}
			&-missed {
				background: #FFC369;
			}
			&-planned {
				background: #83CBFF;
			}
			&-todo {
				background: #F0F4F4;
			}
		}

		&-progress-legend-details {
			@apply w-full;

			tr {
				td {
					span {
						@apply text-principal-gray text-left align-middle;
					}
				}

				td:nth-child(1) {
					width: 5rem;
					min-width: 5rem;

					img {
						@apply inline-block align-middle;
					}

					span {
						@apply inline-block mx-2 text-center text-white h-6 w-10;
					}
				}

				td:nth-child(2) {
					width: 7rem;
				}
			}
		}
	}
</style>