<template>
  <div class="mt-4 border-line-left border rounded p-4">
    <div
      v-for="intern in promotion.users"
      :key="intern.id"
      class="flex p-2 my-2 border-b border-gray-light"
    >
      <template v-if="interns[intern.id]">
        <p class="flex-1">{{interns[intern.id].first_name}} {{interns[intern.id].name}}</p>
        <p class="flex-1">{{interns[intern.id].userOrganisations.map((orga) => orga.organisations.name).join(' / ') || '-'}}</p>
      </template>

      <div
        v-if="canRemove(interns[intern.id])"
        class="flex justify-center text-xl font-bold items-center cursor-pointer col-span-1"
        @click="onRemoveInternClick(intern)"
      >
        <div class="border border-gray-light rounded-full text-center w-8 h-8">-</div>
      </div>
      <!-- Placeholder to align item without the button to the others -->
      <div
        v-else
        class="w-8"
      />
    </div>

    <div v-if="isAddingIntern" class="col-span-3">
      <v-select
        class="w-full"
        :options="internsList"
        :filterable="true"
        label="name"
        placeholder="Nom/Prénom de l'utilisateur"
        @input="onInternSelect"
      />
    </div>

    <div
      v-if="!isReadOnly"
      class="flex justify-center text-xl font-bold items-center cursor-pointer"
      :class="{ 'mt-8': !!interns.length }"
      @click="onAddInternClick"
    >
      <div class="shadow-twn rounded-full text-center w-8 h-8">+</div>
    </div>
    <div class="w-1/2 ml-auto mt-8 border-t border-principal-gray">
      <div class="flex justify-between px-4 mt-4">
        <div>Total de stagiaires</div>
        <div>{{ this.promotion.users.length }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    promotion: {
      type: Object,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      isAddingIntern: false
    }
  },
  computed: {
    ...mapState('Utils', ['userList']),
    ...mapState('Auth', [ 'userInfo' ]),
    ...mapGetters('Auth', [ 'isAdmin' ]),
    internsList() {
      if (!this.userList)
        return []

      let filter = (user) => {
        return !this.promotion.users.some((intern) => (user.id == intern.id))
      }

      if (this.isAdmin && this.promotion.creatorId !== this.userInfo.id) {
        filter = (user) => {
          return !this.promotion.users.some((intern) => (user.id == intern.id)) && (user.id === this.userInfo.id || user.creator_id === this.userInfo.id)
        }
      }

      return this.userList.filter(filter).map((user) => {
        return {
          id: user.id,
          name: ((user.first_name || '') + ' ' + (user.name || '')),
        }
      })
    },
    interns() {
      if (!this.userList)
        return {}

      return this.userList.reduce((dict, user) => {
        dict[user.id] = user

        return dict
      }, {})
    },
  },
  methods: {
    canRemove(intern) {
      if (this.isAdmin && this.promotion.creatorId !== this.userInfo.id) {
        return (intern.id === this.userInfo.id || intern.creator_id === this.userInfo.id)
      }

      return !this.isReadOnly
    },
    onAddInternClick() {
      this.isAddingIntern = !this.isAddingIntern
    },
    onInternSelect(intern) {
      this.$emit('add-intern', intern)

      this.isAddingIntern = false
    },
    onRemoveInternClick(intern) {
      this.$emit('remove-intern', intern)
    },
  },
};
</script>