import utils from "@/utils/trackingReportTemplate"

const startOfDayTimestamp = (new Date()).setHours(0, 0, 0, 0)
const endOfDayTimestamp = (new Date()).setHours(24, 0, 0, 0)

function toStartOfDayDate(d) {
	const date = new Date(d)

	if (date.getHours() !== 0) {
		date.setHours(0, 0, 0, 0) // Start of day
	}

	return date
}

function toEndOfDayDate(d) {
	const date = new Date(d)

	if (date.getHours() !== 0) {
		date.setHours(24, 0, 0, 0) // End of day
	}

	return date
}

export function getSequenceStateForUser(isSequenceDone, latestSequenceDate, groupSequence, groupType) {
	// Invalid group sequence object/id
	if (!groupSequence?.id) {
		return 'todo'
	}

	if (isSequenceDone) {
		return 'done'
	}

	// Check if sequence is no longer available
	if (toEndOfDayDate(groupSequence.endDate).getTime() <= startOfDayTimestamp) {
		// Check if an admin as planned a catch up
		if (latestSequenceDate?.getTime() >= startOfDayTimestamp) {
			return 'planned'
		}

		return 'late'
	}

	// Check if user can and has planned the sequence
	if (groupType.slug === 'individual_promotion') {
		// Check if the planned date has been missed
		if (latestSequenceDate) {
			return (latestSequenceDate?.getTime() <= startOfDayTimestamp ? 'missed' : 'planned')
		}

		// Sequence is not done or individually planned and is still available
		return 'todo'
	}

	// Sequence is not done and still available
	return (toStartOfDayDate(groupSequence.startDate).getTime() <= startOfDayTimestamp ? 'missed' : 'planned')
}

export function userLatestSequenceDates(user, groupSequences) {
	return groupSequences.reduce((sequenceDates, sequence) => {
		if (sequence.userID == user.id) {
			// Fix start date GMT delta if needed
			const startDate = toStartOfDayDate(sequence.startDate)

			// Set or replace sequence date only if it's the latest one
			if (!sequenceDates[sequence.id] || sequenceDates[sequence.id].getTime() < startDate.getTime()) {
				sequenceDates[sequence.id] = startDate
			}
		}

		return sequenceDates
	}, {})
}

export function isSequenceDone(sequence, isDone, loginPerDay, latestSequenceDatesById, group) {
	if (isDone) {
		return true
	}

	// Check if the user has nothing to do in this sequence
	if (sequence.level_scenario_limit <= 0 && sequence.scenarios.length <= 0) {
		// Check if the user has logged in at the right date
		let startDate = latestSequenceDatesById[sequence.id]?.startDate
		let endDate = latestSequenceDatesById[sequence.id]?.endDate

		if (!startDate) {
			startDate = group.sequencesById[sequence.id]?.startDate
		}

		if (!endDate) {
			endDate = group.sequencesById[sequence.id]?.endDate
		}

		if (!startDate || !endDate) {
			return false
		}

		const startTime = toStartOfDayDate(startDate).getTime()
		const endTime = toEndOfDayDate(endDate).getTime()

		for (let dateTime = startTime; dateTime < endTime; dateTime += 86400) {
			const dayId = utils.dayId(new Date(dateTime))
			
			if (loginPerDay[dayId]) {
				return true
			}
		}
	}

	return false
}

export function sequenceState(sequencesDoneById, loginPerDay, latestSequenceDatesById, courseSequences, groupSequencesById, groupType, group) {
	return courseSequences.reduce((dict, sequence) => {
		const isDone = isSequenceDone(sequence, !!sequencesDoneById?.[sequence.id], loginPerDay, latestSequenceDatesById, group)

		dict[sequence.id] = getSequenceStateForUser(isDone, latestSequenceDatesById[sequence.id], groupSequencesById[sequence.id], groupType)

		return dict
	}, {})
}

export function sequencesState(sequenceStateByID, courseSequences, groupSequencesById) {
	let state = 'todo'

	// Check each sequence state
	for (let i = 0; i < courseSequences.length; i += 1) {
		const sequence = courseSequences[i]

		switch (sequenceStateByID[sequence.id]) {
			case 'late':
				return 'late'

			case 'missed':
				state = 'missed'
				break

			case 'done':
				if (state !== 'missed') {
					state = 'done'
				}
				break

			case 'planned':
				if (state === 'todo') {
					state = 'planned'
				} else if (state !== 'missed' && toStartOfDayDate(groupSequencesById[sequence.id]?.startDate).getTime() < endOfDayTimestamp) {
					state = 'planned'
				}
				break

			case 'todo':
				if (state !== 'missed' && toStartOfDayDate(groupSequencesById[sequence.id]?.startDate).getTime() < endOfDayTimestamp) {
					state = 'todo'
				}
				break
			
		}
	}

	return state
}