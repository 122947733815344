<template>
    <div class="user-detail mt-8 md:mt-0">
        <div class="buttons flex flex-row justify-around md:justify-between">
            <button type="button" class="twn-button text-xs" @click="goBack">Retour</button>

            <div class="ml-auto">
                <button
                    v-if="!creating && !isReadOnly"
                    type="button"
                    class="twn-button text-xs danger mr-4"
                    @click="showDeleteModal"
                >
                    Supprimer le profil
                </button>
                <button
                    v-if="!isReadOnly"
                    type="button"
                    class="twn-button text-xs"
                    @click="createOrEdit"
                >
                    Enregistrer le profil
                </button>
            </div>
        </div>
        <div class="flex flex-col">
            <div class="twn-card user-card mt-10 mb-8">
                <div class="flex justify-between items-center">
                    <div class="uppercase font-principal-bold text-sm" v-if="creating">Ajouter un profil</div>
                    <div class="uppercase font-principal-bold text-sm" v-else-if="isReadOnly">Détails du profil</div>
                    <div class="uppercase font-principal-bold text-sm" v-else>Éditer le profil</div>
                    <button
                        v-if="!creating && !isReadOnly"
                        type="button" 
                        class="twn-button text-xs"
                        @click="sendValidationCode"
                        :disabled="isUserValidated"
                    >
                        Envoyer un code d'inscription
                    </button>
                </div>
                <hr>
                <form class="flex flex-row flex-wrap justify-around">
                    <div class="left">
                        <!-- Email -->
                        <div class="input-group mt-0 md:mt-10">
                            <label for="email">Adresse e-mail</label>
                            <div class="relative">
                                <b-form-input
                                    id="email"
                                    type="email"
                                    :state="emailValidation && !emailExist"
                                    :plaintext="isReadOnly"
                                    v-model="fields.email"
                                />
                                <p
                                    v-if="emailExist"
                                    class="absolute text-dashboard-red text-sm mt-1"
                                >
                                    Email déjà utilisé par <router-link :to="{ name: 'users-edit', params: { itemID: emailExist.id }}">"{{ emailExist.first_name }} {{ emailExist.name }}"</router-link>
                                </p>
                            </div>
                        </div>

                        <!-- Password -->
                        <div
                            v-if="!isReadOnly"
                            class="input-group"
                        >
                            <label for="password">Mot de passe</label>
                            <b-form-input
                                id="password"
                                type="text"
                                :disabled="creating && fields.role == 'user'"
                                :placeholder="(creating && fields.role == 'user' ? 'Défini à la première connexion' : '')"
                                v-model="fields.password"
                            />
                        </div>

                        <!-- Lastname -->
                        <div class="input-group">
                            <label for="name">Nom</label>
                            <b-form-input
                                id="name"
                                type="text"
                                :state="nameValidation"
                                :plaintext="isReadOnly"
                                v-model="fields.name"
                            />
                        </div>

                        <!-- Name -->
                        <div class="input-group">
                            <label for="firstName">Prénom</label>
                            <b-form-input
                                type="text"
                                id="firstName"
                                :plaintext="isReadOnly"
                                v-model="fields.firstName"
                            />
                        </div>

                        <!-- Registration date -->
                        <div class="input-group">
                            <label for="registrationDate">Date d'inscription</label>
                            <b-form-datepicker
                                v-if="!isReadOnly"
                                id="registrationDate"
                                reset-button
                                label-reset-button="Effacer"
                                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
                                v-model="fields.registrationDate"
                            />
                            <p
                                v-else
                                class="w-2/3 mb-2"
                            >
                                {{ (fields.registrationDate !== null && fields.registrationDate.split('-').reverse().join('/')) || 'Non renseigné' }}
                            </p>
                        </div>
                    </div>
                    <div class="right">
                        <!-- Organizations -->
                        <div class="input-group">
                            <label for="organisation">Organisation(s)</label>
                            <v-select
                                v-if="!isReadOnly"
                                id="organisation"
                                class="twn-select"
                                label="name"
                                multiple
                                :options="organizationList"
                                :reduce="orga => orga.id"
                                v-model="fields.organizations"
                            />
                            <p
                                v-else
                                class="w-2/3 mb-2"
                            >
                                {{ organizationList.filter(o => fields.organizations.indexOf(o.id) > -1).map(o => o.name).join(',') }}
                            </p>
                        </div>

                        <!-- Status -->
                        <div class="input-group">
                            <label for="status">Statut</label>
                            <v-select
                                v-if="!isReadOnly"
                                id="status"
                                class="twn-select"
                                label="name"
                                :options="statusList"
                                :reduce="status => {
                                    return status.slug
                                }"
                                v-model="fields.status"
                            >
                                <template #search="{attributes, events}">
                                    <input
                                        class="vs__search"
                                        v-bind="attributes"
                                        v-on="events"
                                    />
                                </template>
                            </v-select>
                            <p
                                v-else
                                class="w-2/3 mb-2"
                            >
                                {{ (fields.status !== null && statusList.filter(s => fields.status === s.slug)[0].name) || 'Non renseigné' }}
                            </p>
                        </div>

                        <!-- Company -->
                        <div class="input-group" v-if="fields.status && fields.status == 2">
                            <label for="company">Employeur</label>
                            <b-form-input
                                id="company"
                                type="text"
                                :plaintext="isReadOnly"
                                v-model="fields.company"
                            />
                        </div>

                        <!-- Role -->
                        <div class="input-group" v-if="(fields.role && fields.role !== 'superadmin') || (isAdmin || isSuperAdmin)">
                            <label for="role">Rôle</label>
                            <v-select
                                v-if="!isReadOnly"
                                v-model="fields.role"
                                id="role"
                                :options="roleList"
                                label="name"
                                class="twn-select"
                                :reduce="role => role.slug"
                                :clearable="false"
                            />
                            <p
                                v-else
                                class="w-2/3 mb-2"
                            >
                                {{ roleNameBySlug[fields.role] || 'Inconnu' }}
                            </p>
                        </div>

                        <!-- Seniority -->
                        <div class="input-group">
                            <label for="seniority">Ancienneté</label>
                            <v-select
                                v-if="!isReadOnly"
                                id="seniority"
                                class="twn-select"
                                label="name"
                                :options="seniorityList"
                                :reduce="seniority => seniority.slug"
                                v-model="fields.seniority"
                            />
                            <p
                                v-else
                                class="w-2/3 mb-2"
                            >
                                {{ (fields.seniority !== null && seniorityList.filter(s => fields.seniority === s.slug)[0].name) || 'Non renseigné' }}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <button
                    v-if="!creating && isSuperAdmin"
                    class="float-right twn-button text-xs mb-6"
                    type="button"
                    @click="showCheckProgressModal"
                >
                    Déblocage session libre
                </button>
                <button
                    v-if="!creating && isTWN"
                    class="float-right twn-button text-xs mb-6 mr-2"
                    type="button"
                    @click="showDebugProgressModal"
                >
                    Debug progression
                </button>

                <b-table
                    :items="fields.promotions"
                    :fields="promotionArrFields"
                >
                    <template #cell(title)="data">
                        <router-link :to="{ name: 'promotions-edit', params: { itemID: data.item.id }}">
                            {{ data.value }}
                        </router-link>
                    </template>
                </b-table>
            </div>

            <div v-if="fields.id && fields.promotions" class="mt-8">
              <UserDashboard :user="fields" />
            </div>

        </div>
        <b-modal ref="delete-modal-detail-user" class="bootstrap" centered hide-footer id="delete-modal-detail-user" hide-header>
            <div class="d-block text-center my-6 uppercase font-semibold">
                <h3>Confirmer la suppression</h3>
            </div>
            <div class="flex flex-row justify-evenly items-center">
                <button type="button" class="mt-4 twn-button" @click="$bvModal.hide('delete-modal-detail-user')">Retour</button>
                <button type="button" class="mt-4 twn-button danger" @click="deleteItem">Supprimer</button>
            </div>
        </b-modal>

        <CheckUserProgressModal
            ref="check-progress-modal"
            :user-id="getUser.id"
            :first-name="fields.firstName"
            :last-name="fields.name"
        />

        <DebugUserProgressModal
            ref="debug-progress-modal"
            :user-id="getUser.id"
            :first-name="fields.firstName"
            :last-name="fields.name"
        />
    </div>
</template>
<script>

import { mapGetters, mapActions, mapState } from 'vuex';
import { FC_DELETE_ITEM } from '@/graphql/list'
import UserDashboard from '@/components/dashboard/User'
import CheckUserProgressModal from '@/components/user/CheckUserProgressModal'
import DebugUserProgressModal from '@/components/user/DebugUserProgressModal'
import router from '@/router/index'


export default {
    name: 'UserDetail',
    props: {
        itemID: {
            type: String,
            required: false
        },
        previousPage: {
            type: Number,
            required: false
        }
    },
    components:{
        UserDashboard,
        CheckUserProgressModal,
        DebugUserProgressModal,
    },
    watch: {
        async itemID() {
            if (this.getCurrentID !== -1 || this.itemID){
                let id = this.itemID;
                if (this.getCurrentID !== -1 && !this.itemID){
                    id = this.getCurrentID;
                }
                await this.setUser(id)
                this.mapFields();
            }
        },
    },
    async mounted() {
        if (this.getCurrentID !== -1 || this.itemID){
            let id = this.itemID;
            if (this.getCurrentID !== -1 && !this.itemID){
                id = this.getCurrentID;
            }
            await this.setUser(id)
            this.mapFields();
        } else {
            this.creating = true;
            await this.initUserEditLists();
            this.organizationList = this.getOrganizationList;
            // this.statusList = this.getStatusList;
        }
    },
    data() {
        return {
            isUserValidated: false,
            star: {
                number: 8,
                colored: 2
            },
            selectedID: '',
            organizationList: [],
            selectedRole: null,
            selectedStatus: null,
            statusList: [
                {
                    slug: 0,
                    name: 'GI'
                },
                {
                    slug: 1,
                    name: 'RS'
                },
                {
                    slug: 2,
                    name: 'Autre poste dans le logement social'
                }
            ],
            experienceList: [
                {
                    slug: true,
                    name: 'Navigation libre'
                },
                {
                    slug: false,
                    name: 'Navigation guidée'
                },
            ],
            seniorityList: [
                {
                    slug: 0,
                    name: '0 an'
                },
                {
                    slug: 1,
                    name: '0-2 ans'
                },
                {
                    slug: 2,
                    name: '2-5 ans'
                },
                {
                    slug: 3,
                    name: '5-10 ans'
                },
                {
                    slug: 4,
                    name: '+10 ans'
                },
                {
                    slug: 5,
                    name: 'Non concerné'
                },
            ],
            fields: {
                id: '',
                firstName: '',
                name: '',
                organizations: [],
                status: null,
                email: '',
                seniority: null,
                company: '',
                experienced: false,
                role: 'user',
                password: '',
                registrationDate: null,
                creatorId: null,
            },
            promotionArrFields:[
				{
					key: "index",
					label: "",
					sortable: false
				},
				{
					key: "title",
					label: "Promotions associées",
					sortable: false
                },
				{
					key: "start_date",
					label: "Date de début",
					sortable: false
                },
				{
					key: "end_date",
					label: "Date de fin",
					sortable: false
				},
			],
            delayer: true,
            creating: false,
            roleNameBySlug: {
                "user": "Utilisateur",
                "organisationadmin": "Organisation Admin",
                "administrator": "Admin",
                "superadmin": "Super Admin",
            }
        }
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
        ...mapState('Utils', ['userList']),
        ...mapGetters('Auth', [ 'isSuperAdmin', 'isAdmin' ]),
        ...mapGetters('EditItem', ['getUser', 'getOrganizationList', 'getStatusList', 'getCurrentID']),
        isReadOnly() {
            if (this.isAdmin) {
                // Admin can create user but only edit user that they created
                return !this.creating && this.fields.creatorId !== this.userInfo.id
            }

            return !this.isSuperAdmin
        },
        nameValidation(){
            return this.fields.name !== undefined && this.fields.name !== null && this.fields.name !== '';
        },
        emailValidation() {
            return (this.fields.email !== undefined && this.fields.email !== null && this.fields.email !== '')
        },
        emailExist() {
            if (!this.emailValidation) {
                return false
            }

            // Check if email exist when creating/editing an user
            return this.userList.find((user) => {
                return (user.email == this.fields.email && user.id != this.fields.id)
            })
        },
        allValid(){
            return this.nameValidation
                && this.emailValidation
                && !this.emailExist
        },
        roleList() {
            const list = [
                {
                    slug: "user",
                    name: "Utilisateur",
                },
                {
                    slug: "organisationadmin",
                    name: "Organisation Admin",
                },
            ]

            if (this.isSuperAdmin){
                list.push({
                    slug: "administrator",
                    name: "Admin",
                })
                list.push({
                    slug: "superadmin", 
                    name: "Super Admin",
                })
            }

            return list
        },
        isTWN() {
            return this.isSuperAdmin && this.userInfo.email.indexOf('thewalkingnerds.com') === (this.userInfo.email.length - 19)
        }
    },
    methods: {
        ...mapActions('EditItem', ['setUser', 'editUser', 'initUserEditLists', 'addUser']),
        goBack(){
            router.push({name:'users-list', params: {
                previousPage: this.previousPage
            }})
        },
        showDeleteModal(){
            this.$refs['delete-modal-detail-user'].show();
        },
        showCheckProgressModal() {
            this.$refs['check-progress-modal'].show();
        },
        showDebugProgressModal() {
            this.$refs['debug-progress-modal'].show();
        },
        async deleteItem(){
            await this.$apollo.query({
                query: FC_DELETE_ITEM("users"),
                variables: {
                    id: this.fields.id
                }
            })
            this.$refs['delete-modal-detail-user'].hide();
            this.$router.push({ name: 'users-list' })
        },
        mapFields(){
            this.fields = this.getUser;
            this.organizationList = this.getOrganizationList;
            // this.statusList = this.getStatusList;
        },
        createOrEdit(e){
            e.preventDefault();
            if(this.creating){
                this.confirmCreate();
            } else {
                this.confirmEdit();
            }
        },
        async confirmEdit(){
            // if (this.allValid && this.delayer){
                await this.editUser(this.fields);
                this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
            //     this.delayer = false;
            //     setTimeout(this.resetDelayer, 500);
            // }
        },
        async confirmCreate() {
            // if (this.allValid && this.delayer){
            //     this.delayer = false;
            //     //create
                const id = await this.addUser(this.fields);
                this.$bvToast.toast('Vos modifications ont bien été enregistrés !', { title: `Succès !` })
                
                await this.$router.push({
                    name: 'users-edit',
                    params: {
                        itemID: id,
                    }
                })

                // Reload user data
                await this.setUser(id)
                this.mapFields();

                this.creating = false;
            //     setTimeout(this.resetDelayer, 500);
            // }
        },
        resetDelayer(){
            this.delayer = true;
        },
        async sendValidationCode() {
            if (this.isUserValidated)
                return

            this.isUserValidated = true

            try {
                await this.$store.dispatch('Auth/sendValidationToken', this.fields.email)//todo: email check ? real email ?
                alert('Code envoyer à ' + this.fields.email)
            } catch (error) {
                this.isUserValidated = false
            }
        },
    },
}
</script>
<style lang="scss">
.user-card{
    > div{
        @apply my-5;
    }
    .left, .right{
        @apply w-full;
        > div {
            @apply flex flex-row justify-around items-center my-5 flex-no-wrap;
            > label {
                @apply text-right mr-5 w-1/3 text-xs;
            }
            > input, > div {
                &:not(.is-invalid):not(.form-control-plaintext){
                    @apply border-line-top;
                }
                &.form-control-plaintext {
                    @apply mb-2;
                }
                @apply w-2/3 flex-auto;
                border-radius: 0.275rem !important;
            }
            .twn-select {
                @apply w-2/3;
                .vs__dropdown-toggle {
                    @apply border-line-top;
                }
                &.is-invalid{
                    .vs__dropdown-toggle {
                        border-color: #dc3545;
                    }
                }
            }
        }
        @screen xs {
            > div > label {
                @apply text-sm;
            }
        }
    }
    @screen md {
        .left, .right {
            > div {
                @apply my-10;
            }
            @apply w-1/2;
            .button-holder{
                @apply flex flex justify-end items-end;
            }
        }
    }
    hr{
        box-sizing: border-box;
        border: 1px solid;
        @apply border-line-top rounded-lg;
    }
    .stars{
        .star{
            @apply w-8 mx-1 fill-current text-gray-star;
            &.complete{
                @apply text-principal-selected;
            }
        }
    }
}
</style>