import { render, staticRenderFns } from "./PromotionList.vue?vue&type=template&id=6d29e166"
import script from "./PromotionList.vue?vue&type=script&lang=js"
export * from "./PromotionList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.19_css-loader@6.10.0_webpack@5.90.3__lodash@4._3b565607f4984d8a9a80d55750768552/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports