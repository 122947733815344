<template>
  <div>
    <List
      listing="promotion"
      :fields="fields"
      order-by="end_date: desc, start_date: asc"
      search-text="Rechercher une promotion"
      :pageSize="pageSize"
      edit-route-name="promotions-edit"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-around hidden">
          <button
            v-if="!isReadOnly"
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddPromotionClick"
          >
            Ajouter une nouvelle promotion
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from "@/components/List";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    List,
  },
  props: {
    pageSize:{
      type: Number,
      default: 30,
      required: false
    }
  },
  data: () => ({
    fields: [
      {
        key: "title",
        label: "Titre",
        sortable: true,
      },
      {
        key: 'course',
        db: `course {
          name
        }`,
        nested: 'name',
        label: "Parcours",
        sortable: true,
      },
      {
        key: 'type',
        db: `type {
          name
        }`,
        nested: 'name',
        label: 'Type',
        sortable: true,
      },
      {
        key: 'start_date',
        label: "Dates",
        sortable: true,
        formatter: (_value, _key, item) => {
          const start = (item.start_date ? item.start_date.split('-').reverse().join('/') : '')
          const end = (item.end_date ? item.end_date.split('-').reverse().join('/') : '')

          return start + ' - ' + end
        }
      },
      {
        key: 'users_aggregate',
        label: "Stagiaires",
        sortable: true,
        db: `users_aggregate {
          aggregate {
            count
          }
        }`,
        nested: 'count'
      },
      {
        key: 'end_date',
        label: 'Statut',
        sortable: true,
        formatter: (_value, _key, item) => {
          const now = new Date()
          const start = new Date(item.start_date)
          const end = new Date(item.end_date)

          return (now < start ? 'À venir' : (now > end ? 'Passé' : 'En cours'))
        },
      }
    ],
  }),
  computed: {
    ...mapGetters('Auth', [ 'isSuperAdmin', 'isAdmin' ]),
    isReadOnly() {
      return !this.isSuperAdmin && !this.isAdmin
    },
  },
  methods: {
    ...mapActions("EditItem", ["clearCurrentID"]),
    onAddPromotionClick() {
      this.clearCurrentID();
      this.$router.push({ name: "promotions-create" });
    },
  },
};
</script>