<template>
    <div>
        <cooc-list 
            listing="users" 
            :fields="fields" 
            :filters="filters" 
            searchText="Rechercher un utilisateur"
            :with-delete-button="false"
            modify-button-text="Éditer la fiche Utilisateur"
        >
            <template v-slot:buttons>
                <div class="md:flex flex-row hidden user-buttons ml-1">
                    <button
                        v-if="!isReadOnly"
                        type="button"
                        class="hidden md:block twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
                        @click="addUser"
                    >
                        Ajouter un utilisateur
                    </button>
                    <button
                        v-if="!isReadOnly"
                        type="button"
                        class="twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
                        @click="importUserList"
                    >
                        Importer des utilisateurs
                    </button>
                </div>
            </template>
            <template v-slot:fab>
                <vue-fab
                    class="md:hidden"
                    fabItemAnimate="alive"
                    fabAliveAnimateBezier="ease-in-out"
                    mainBtnColor="#fff"
                    size="big"
                >
                    <fab-item class="twn-fab import" @clickItem="importUserList" color="#888888" :idx="2" title="Importer des utilisateurs" icon="add" />
                    <fab-item class="twn-fab add" @clickItem="addUser" color="#DC9799" :idx="0.5" title="Ajouter un utilisateur" icon="add" />
                </vue-fab>
            </template>
        </cooc-list>

        <UserImportModal v-model="isImportModalOpen" />
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import List from '@/components/List'
import UserImportModal from '@/components/user/UserImportModal'

export default {
    name: 'UserList',
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: 'Nom',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Prénom',
                    sortable: true,
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true,
                },
                {
                    key: 'promotions',
                    db: `promotions:promotion_users{
                        promotion {
                            title
                        }
                    }`,
                    nested: 'title',
                    label: 'Promotion(s)',
                    sortable: true,
                    formatter: (value) => {
                        return Array.isArray(value) ? value.join(' | ') : value
                    }
                },
                {
                    key: 'role',
                    sortable: true,
                    formatter: (value) => {
                        switch (value){
                            case 'user':
                                return "Utilisateur"
                            case 'organisationadmin':
                                return "Organisation Admin"
                            case 'administrator':
                                return "Admin"
                            case 'superadmin':
                                return "Super Admin"
                        }
                    }
                },
                {
                    key: 'organization',
                    db: `organization:userOrganisations{
                        organisations{
                            name
                        }
                    }`,
                    nested: 'name',
                    label: 'Organisation(s)',
                    sortable: true,
                    formatter: (value) => {
                        return Array.isArray(value) ? value.join(' | ') : value
                    }
                },
                {
                    key: 'registration_date',
                    label: "Date d'inscription",
                    sortable: true,
                    formatter: (_value, _key, item) => {
                        const start = (item.registration_date ? item.registration_date.split('-').reverse().join('/') : 'Aucune')

                        return start
                    }
                },
            ],
            filters: [
                {
                    key:'organization',
                    label: 'Organisation',
                    multiple: true
                },
                {
                    key:'role',
                    label: 'Rôle'
                },
                {
                    key:'registration_date',
                    label: 'Date d\'inscription',
                    type: 'date',
                }
            ],
            isImportModalOpen: false,
        }
    },
    props:{
        previousPage: {
            type: Number,
            required: false,
            default: null
        }
    },
    components: {
        'cooc-list': List,
        UserImportModal,
    },
    computed: {
        ...mapGetters('Auth', [ 'isSuperAdmin', 'isAdmin' ]),
        isReadOnly() {
            return !this.isSuperAdmin && !this.isAdmin
        },
    },
    methods:{
        ...mapActions('EditItem', ['clearCurrentID']),
        addUser(){
            this.clearCurrentID();
            this.$router.push({name:'users-create'});
        },
        importUserList() {
            this.isImportModalOpen = true
        },
    }
}
</script>
<style lang="scss">
// .user-buttons{
//     flex-basis: 33%;
// }
</style>