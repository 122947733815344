import { render, staticRenderFns } from "./CourseDetail.vue?vue&type=template&id=545f5fc2&scoped=true"
import script from "./CourseDetail.vue?vue&type=script&lang=js"
export * from "./CourseDetail.vue?vue&type=script&lang=js"
import style0 from "./CourseDetail.vue?vue&type=style&index=0&id=545f5fc2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.19_css-loader@6.10.0_webpack@5.90.3__lodash@4._3b565607f4984d8a9a80d55750768552/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545f5fc2",
  null
  
)

export default component.exports