import bcrypt from 'bcryptjs'

import router from '@/router/index'
import { GC_GET_USER_N_ORGAS, GC_EDIT_USER, GC_EDIT_USER_AND_PASSWORD, GC_ADD_USER_ONE } from '@/graphql/edit'
import { GC_GET_ORGA_ONE, GC_EDIT_ORGA_ONE, GC_ADD_ORGA_ONE, GC_ADD_MULTIPLE_USER } from '../graphql/edit';
import apollo from '@/apolloClient'

function bcryptPassword(newPassword) {
    const salt = bcrypt.genSaltSync()
    newPassword = bcrypt.hashSync(newPassword, salt)
    return newPassword
}

export default {
    namespaced: true,
    state: {
        currentID: -1,
        user: {
            id: '',
            firstName: '',
            name: '',
            email: '',
            organizations: [],
            status: '',
            registrationDate: null,
            creatorId: null,
        },
        organization: {
            id: '',
            address: '',
            address2: '',
            post_code: 0,
            city: '',
            name: '',
            country: ''
        },
        organizationList: [],
        countryList: [],
        statusList: [],
        userList: [],
        federationList: []
    },
    getters:{
        getUser(state){
            return state.user;
        },
        getOrganization(state){
            return state.organization;
        },
        getOrganizationList(state){
            return state.organizationList;
        },
        getStatusList(state){
            return state.statusList;
        },
        getCountryList(state){
            return state.countryList;
        },
        getCurrentID(state){
            return state.currentID;
        },
        getUserList(state){
            return state.userList;
        }
    },
    mutations: {
        SET_USER(state, user){
            let orgaArr = user.userOrganisations.map(orga => orga.organisations.id);
            let promoArr = user.promotion_users.map(promo => {
                return {
                    id: promo.promotion.id,
                    title: promo.promotion.title,
                    start_date: promo.promotion.start_date,
                    end_date: promo.promotion.end_date,
                    type: promo.promotion.type,
                }
            });
            state.user = {
                id: user.id,
                firstName: user.first_name,
                name: user.name,
                email: user.email,
                organizations: orgaArr,
                seniority: user.seniority,
                status: user.status,
                company: user.company,
                experienced: user.experienced,
                promotions: promoArr,
                role: user.role,
                registrationDate: user.registration_date || null,
                creatorId: user.creator_id || null,
            };
        },
        SET_ORGA(state, orga){
            state.organization = orga;
        },
        SET_ORGA_LIST(state, orgaList){
            state.organizationList = orgaList;
        },
        SET_STATUS_LIST(state, statusList){
            if (statusList && Array.isArray(statusList)){
                let filteredArr = statusList.filter(e => e.experienced !== null);
                state.statusList = filteredArr;
            }
        },
        SET_USER_LIST(state, userList){
            state.userList = userList;
        },
        SET_FEDERATIONS_LIST(state, fedList){
            state.federationList = fedList
        }
    },
    actions: {
        async initUserEditLists({commit, rootState}){
            commit('SET_ORGA_LIST', rootState['Utils'].organisationNameList);
            commit('SET_STATUS_LIST', rootState['Utils'].statusNameList);
        },
        async initOrgaEditLists({commit, rootState}){
            commit('SET_USER_LIST', rootState['Utils'].userList);
            commit('SET_FEDERATIONS_LIST', rootState['Utils'].federationList);
        },
        async setUser({commit, state}, userID){
            let id = userID;
            if (!id){
                router.push('/404')
                return;
            }
            state.currentID = id;
            const response = await apollo.query({
                query: GC_GET_USER_N_ORGAS,
                variables: {
                    id: id
                }
            })
            commit('SET_USER', response.data.users[0]);
            commit('SET_ORGA_LIST', response.data.organisations_aggregate.nodes);
            commit('SET_STATUS_LIST', response.data.users_aggregate.nodes);
        },
        async addUser({commit, dispatch}, userInfo){
            const password = bcryptPassword(userInfo.password)

            let orgaArr = userInfo.organizations.map(id => {
                return {
                    organisation: id
                }
            })
            let response = await apollo.mutate({
                mutation: GC_ADD_USER_ONE,
                variables: {
                    email: userInfo.email,
                    fName: userInfo.firstName,
                    lName: userInfo.name,
                    experienced: userInfo.experienced,
                    orgas: orgaArr,
                    seniority: userInfo.seniority,
                    status: userInfo.status,
                    company: userInfo.company,
                    role: userInfo.role,
                    password,
                    registrationDate: userInfo.registrationDate || null,
                }
            })
            await dispatch('setUser', response.data.insert_users_one.id);

            // Reload user list utils cache
            await dispatch('Utils/getUsers', null, { root: true })

            return response.data.insert_users_one.id
        },
        async editUser({dispatch}, userInfo){
            let orgaArr = userInfo.organizations.map(id => {
                return {
                    user: userInfo.id,
                    organisation: id
                }
            })
            
            let variables = {
                email: userInfo.email,
                fName: userInfo.firstName,
                lName: userInfo.name,
                status: userInfo.status,
                seniority: userInfo.seniority,
                company: userInfo.company,
                id: userInfo.id,
                experienced: userInfo.experienced,
                orgas: orgaArr,
                role: userInfo.role,
                registrationDate: userInfo.registrationDate || null,
            }

            if (userInfo.password && userInfo.password != '') {
                variables.password = bcryptPassword(userInfo.password)
            }

            await apollo.mutate({
                mutation: (variables.password ? GC_EDIT_USER_AND_PASSWORD : GC_EDIT_USER),
                variables
            })

            dispatch('setUser', userInfo.id);

            // Reload user list utils cache
            await dispatch('Utils/getUsers', null, { root: true })
        },
        async setOrganization({commit, state}, organizationID){
            let id = organizationID;
            if (!id){
                router.push('/404')
                return;
            }
            state.currentID = id;
            const response = await apollo.query({
                query: GC_GET_ORGA_ONE,
                variables: {
                    id: id
                }
            })
            commit('SET_ORGA', response.data.organisations_by_pk);
        },
        async editOrganization({dispatch}, orgaInfo){
            delete orgaInfo.__typename;
            if (orgaInfo.userOrganisations){
                delete orgaInfo.userOrganisations
            }
            if (orgaInfo.admin !== undefined){
                delete orgaInfo.admin
            }
            if (orgaInfo.federation !== undefined){
                delete orgaInfo.federation
            }
            await apollo.mutate({
                mutation: GC_EDIT_ORGA_ONE,
                variables: {
                    id: orgaInfo.id,
                    orga: orgaInfo
                }
            })
            dispatch('setOrganization', orgaInfo.id);
        },
        async addOrganization({dispatch}, orgaInfo){
            delete orgaInfo.__typename;
            let response = await apollo.mutate({
                mutation: GC_ADD_ORGA_ONE,
                variables: orgaInfo
            })
            dispatch('setOrganization', response.data.insert_organisations_one.id);
        },
        clearCurrentID({state}){
            state.currentID = -1;
        },
        async importUsers({ dispatch }, { list: usersList, info, sendInviteEmails, registrationDate }) {
            // Format user data for GQL mutation
            const users = usersList.map((userData) => {
                const user = {
                    email: userData.email.toLowerCase(),
                    first_name: userData.first_name,
                    name: userData.last_name,
                    role: 'user',
                    promotion_users: { data: [] },
                    registration_date: registrationDate || null,
                }

                // Add group if needed
                if (userData.group_id) {
                    user.promotion_users.data.push({
                        promotion_id: userData.group_id
                    })
                }

                return user
            })

            // Execute GQL mutation
            let response = null

            try {
                response = await apollo.mutate({
                    mutation: GC_ADD_MULTIPLE_USER,
                    variables: {
                        users
                    }
                })
            } catch (error) {
                // Handle uniq email constraint errors
                if (error.graphQLErrors[0].extensions.code === "constraint-violation") {
                    // Default error message
                    let message = 'Une ou plusieurs adresses email sont déjà utilisé pour une inscription veuillez les retirer du document'

                    // Try to find the index of the user causing the error to improve the error message
                    const matches = error.graphQLErrors[0].extensions.path.match(/objects\[(\d)\]/)

                    if (matches && matches.length > 1) {
                        message = `L'utilisateur avec l'adresse email ${users[matches[1]].email} existe déjà veuillez le retirer du document`
                    }

                    throw new Error(message)
                } else {
                    throw error
                }
            }

            // Check if we need to also send emails/register all users
            if (sendInviteEmails) {
                const waitPromise = (delay) => {
                    return new Promise(function(resolve) {
                        setTimeout(resolve, delay);
                    });
                }

                // Register every user inserted in the DB
                for (var i = 0; i < users.length; i++) {
                    info.message = `Envoie des emails ${i + 1}/${users.length}...`
                    info.type = 'progress'

                    try {
                        await dispatch('Auth/sendValidationToken', users[i].email, { root: true })
                    } catch (error) {
                        info.message = `Erreur d'envoie de l'email à ${users[i].email}. Passage à l'utilisateur suivant...`
                        info.type = 'error'
                        info.errors.push(`Erreur d'envoie de l'email à ${users[i].email}.`)
                        await waitPromise(1500)
                    }
                }
            }

            // Reload user list utils cache
            await dispatch('Utils/getUsers', null, { root: true })

            return response?.data.insert_users.affected_rows
        }
    }
}