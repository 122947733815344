import apollo from '@/apolloClient'

import {
    GC_GET_PROMOTION_TYPE_LIST,
    GC_GET_PROMOTION_LIST,
    GC_GET_PROMOTION_BY_ID,
    GC_ADD_PROMOTION_ONE,
    GC_UPDATE_PROMOTION_BY_ID,
    GC_DELETE_PROMOTION_BY_ID,
    GC_UPDATE_USERS_AND_SEQUENCES_IN_PROMOTION_BY_ID,
} from '@/graphql/promotion'

export default {
    namespaced: true,
    state: {
        list: [],
        items: {},
        typeList: [],
    },
    mutations: {
        SET_PROMOTION_LIST(state, promotions) {
            state.list = (promotions || []);
        },
        SET_PROMOTION_TYPE_LIST(state, types) {
            state.typeList = types;
        },
        SET_PROMOTION(state, promotion) {
            if (!promotion || !promotion.id)
                return

            state.items[promotion.id] = promotion
        },
    },
    actions: {
        async getTypeList({ commit }) {
            const response = await apollo.query({ query: GC_GET_PROMOTION_TYPE_LIST })

            commit('SET_PROMOTION_TYPE_LIST', response.data.promotion_type)
        },
        async getList({ commit }) {
            const response = await apollo.query({ query: GC_GET_PROMOTION_LIST })

            commit('SET_PROMOTION_LIST', response.data.promotion)
        },
        async getByID({ commit }, id) {
            let response = await apollo.query({
                query: GC_GET_PROMOTION_BY_ID,
                variables: { id }
            })

            if (!response.data.promotion_by_pk) {
                return
            }

            commit('SET_PROMOTION', response.data.promotion_by_pk)

            return response.data.promotion_by_pk
        },
        async save(context, data) {
            let response = null
            let result = {}

            let promotion = {
                title: data.title,
                start_date: data.startDate,
                end_date: data.endDate,
                course_id: data.course,
                promotion_type_id: data.type,
            }

            // Update or add the promotion
            if (data.id) {
                // Set id
                promotion.id = data.id

                // Get current sequences association and their ids
                promotion.sequences = []
                // promotion.sequences_ids = []

                // todo/note : shouldn't this be done in the caller ? (ie I don't have to know/don't care how you structure your data... I need MY format :p)
                // todo: compare to old db data and uniquIFY whith course sequences data ?? (or in the caller ?)
                data.sequences.forEach((sequence) => {
                    // promotion.sequences_ids.push(sequence.id)

                    promotion.sequences.push({
                        promotion_id: data.id,
                        sequence_id: sequence.id,
                        user_id: (sequence.userID || null),
                        start_date: sequence.startDate,
                        end_date: sequence.endDate,
                    })
                })

                // Get current users association and their ids
                promotion.users = []
                promotion.users_ids = []

                data.users.forEach((user) => {
                    promotion.users_ids.push(user.id)

                    promotion.users.push({
                        promotion_id: data.id,
                        user_id: user.id,
                    })
                })

                // Update remote data
                if (context.rootGetters['Auth/isAdmin'] && data.creatorId !== context.rootState.Auth.userInfo.id) {
                    const currentPromotion = context.state.items[promotion.id]
                    response = await context.dispatch('saveUsersAndTheirSequences', { currentPromotion, newPromotion: promotion })

                    // Reload promotion data for next save
                    await context.dispatch('getByID', promotion.id)
                } else {
                    response = await apollo.mutate({
                        mutation: GC_UPDATE_PROMOTION_BY_ID,
                        variables: promotion
                    })
                }

                result.success = true
            } else {
                // Get current sequences association
                promotion.sequences = data.sequences.map((sequence) => {
                    return {
                        sequence_id: sequence.id,
                        user_id: (sequence.userID || null),
                        start_date: sequence.startDate,
                        end_date: sequence.endDate,
                    }
                })

                // Get current users association
                promotion.users = data.users.map((user) => {
                    return {
                        user_id: user.id,
                    }
                })

                // Add remote data
                response = await apollo.mutate({
                    mutation: GC_ADD_PROMOTION_ONE,
                    variables: promotion
                })

                result.id = response.data.insert_promotion_one.id
            }

            return result
        },
        // Only update promotion user associations and promotion sequence data with an user id
        async saveUsersAndTheirSequences(context, { currentPromotion, newPromotion }) {
            // Remove promotion sequence without an user id
            const newPromotionSequences = newPromotion.sequences.filter((s) => {
                return !!s.user_id
            })
            const currentPromotionSequences = currentPromotion.sequences.filter((s) => {
                return !!s.user_id
            })

            // Compute which sequence should be deleted
            const startOfDateTimestamp = (d) => {
                let date = new Date(d)

                return date.setHours(0, 0, 0, 0)
            }
            const isSameSequence = (s1, s2) => {
                // promotion_sequence don't have primary key or uniq constraint so we need to compare all fields (except for promotion_id in our specific case)
                if (s1.sequence_id !== s2.sequence_id) {
                    return false
                } else if (s1.user_id !== s2.user_id) {
                    return false
                } else if (startOfDateTimestamp(s1.start_date) !== startOfDateTimestamp(s2.start_date)) {
                    return false
                } else if (startOfDateTimestamp(s1.end_date) !== startOfDateTimestamp(s2.end_date)) {
                    return false
                }

                return true
            }

            const extraSequencesToAdd = []
            const deleteCondition = currentPromotionSequences.reduce((condition, currentSequence) => {
                // Check if the sequence is still present in the new promotion
                const similarSequences = newPromotionSequences.filter((newSequence) => {
                    return isSameSequence(newSequence, currentSequence)
                })

                if (similarSequences.length > 0) {
                    // The sequence still exist in the new promotion, but we need to check for "duplicates" removal
                    const similarSequencesInCurrentPromotion = currentPromotionSequences.filter((s) => {
                        return isSameSequence(s, currentSequence)
                    })

                    if (similarSequences.length === similarSequencesInCurrentPromotion.length) {
                        // The sequence (and all his potential "duplicates") still exist in the new promotion
                        return condition
                    } else {
                        // We found multiple match in the new and current promotion, so we need to check if some, or all, of them need to be deleted/added

                        // The condition is going to delete all of them, so we need to re-add the missing/remaining ones after the delete
                        let extraSequenceCount = similarSequences.length

                        if (similarSequences.length > similarSequencesInCurrentPromotion.length) {
                            extraSequenceCount -= similarSequencesInCurrentPromotion.length
                        }

                        // Only do this once (needed because we can have multiple "duplicated" sequence in currentPromotion)
                        const alreadyDone = extraSequencesToAdd.find((s) => isSameSequence(s, currentSequence))

                        if (alreadyDone) {
                            // The currentSequence is a "duplicate" and it's already been added to the condition and the extra list
                            return condition
                        }

                        for (var i = 0; i < extraSequenceCount; i++) {
                            extraSequencesToAdd.push({
                                ...currentSequence,
                                promotion_id: newPromotion.id,
                            })
                        }

                        if (similarSequences.length > similarSequencesInCurrentPromotion.length) {
                            // We found more "duplicates" in the new promotion so we shouldn't delete the existing ones
                            return condition
                        }
                    }
                }

                // Add this sequence to the delete condition
                condition.push({
                    sequence_id: { _eq: currentSequence.sequence_id },
                    user_id: { _eq: currentSequence.user_id },
                    start_date: { _eq: currentSequence.start_date },
                    end_date: { _eq: currentSequence.end_date },
                })

                return condition
            }, [])

            // List sequences that need to be added to the promotion
            const newSequences = extraSequencesToAdd.concat(newPromotionSequences.filter((newSequence) => {
                return !currentPromotionSequences.find(currentSequence => isSameSequence(currentSequence, newSequence))
            }))

            return await apollo.mutate({
                mutation: GC_UPDATE_USERS_AND_SEQUENCES_IN_PROMOTION_BY_ID,
                variables: {
                    id: newPromotion.id,
                    delete_promotion_sequence_condition: deleteCondition,
                    new_sequences: newSequences,
                    users_ids: newPromotion.users_ids,
                    new_users: newPromotion.users.filter((u) => {
                        return !currentPromotion.users.find(cu => cu.user_id === u.user_id)
                    }),
                }
            })
        },
        async delete(context, id){
            const response = await apollo.mutate({
                mutation: GC_DELETE_PROMOTION_BY_ID,
                variables: { id }
            })

            return response.data.delete_promotion_by_pk
        },
    }
}